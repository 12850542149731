<template>
	<div
		class="field"
		:class="{ 'is-flex is-justify-content-space-between': isInline }"
	>
		<ws-form-label
			v-if="label || $slots.label"
			:id="id"
			:disabled="disabled"
			:error="error"
			:is-inline="isInline"
			:optional="optional"
			:tooltip="tooltip"
			:tooltip-position="tooltipPosition"
		>
			<slot name="label">
				{{ label }}
			</slot>
		</ws-form-label>
		<label class="label-description" v-if="labelDescription">
			{{ labelDescription }}
		</label>
		<div
			class="control is-expanded inputField"
			:class="{ 'has-text-danger': !!error }"
		>
			<div class="is-flex">
				<vue-multiselect
					ref="vSelect"
					track-by="value"
					label="label"
					:aria-labelledby="id"
					:model-value="content"
					:placeholder="placeholder"
					:options="options"
					:open-direction="openDirection"
					:searchable="true"
					:close-on-select="closeOnSelect"
					:multiple="true"
					:allow-empty="allowEmpty"
					:max="max"
					:loading="loading"
					:allow-null="allowNull"
					:preserve-search="true"
					:disabled="disabled"
					:data-testid="dataTestid"
					:taggable="taggable"
					@tag="addTag"
					:tag-placeholder="tagPlaceholder"
					@update:model-value="handleChanged"
					@open="handleOpen"
					@close="handleClose"
					:style="{ width: '100%' }"
				>
					<template #tag="{ option, remove }">
						<div class="multiselect__tags-wrap">
							<span
								class="multiselect__tag"
								v-tooltip="
									option.tooltip
										? {
												content: option.tooltip,
												html: true
											}
										: null
								"
							>
								<span>{{ option.label }}</span>
								<i
									tabindex="1"
									class="multiselect__tag-icon"
									@click="remove(option)"
								></i>
							</span>
						</div>
						<slot name="afterTag" :option="option" />
					</template>
					<template #singleLabel="props">
						<slot name="customSingleLabel" :option="props.option">
							<label :id="id">
								{{ props.option.label }}
							</label>
						</slot>
					</template>

					<template #option="props">
						<slot name="customOption" :option="props.option">
							{{ props.option.label || props.option.$groupLabel }}
						</slot>
					</template>

					<template #caret>
						<ws-icon
							icon="angle-right"
							class="multiselect__select"
						></ws-icon>
					</template>
				</vue-multiselect>
			</div>
			<p class="help is-danger" v-if="!!error">
				{{ error }}
			</p>
		</div>
	</div>
</template>

<script>
import EventBus from "@/eventbus.js";
import { generateRandomId } from "@/helpers/functions.helper.js";
import VueMultiselect from "vue-multiselect";

export default {
	name: "WsFormMultiSelect",
	props: {
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},
		label: {
			type: [String, null],
			default: null
		},
		labelDescription: {
			type: [String, null],
			default: null
		},
		optional: {
			type: Boolean,
			default: false
		},
		modelValue: {
			type: [Array, null],
			default: () => []
		},
		// to display tags in the same order of addition (order inside modalValue)
		// not based on their order inside the options array
		isOrderedValues: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: [String, null],
			default: null
		},
		options: {
			type: Array,
			default: () => []
		},
		openDirection: {
			type: [String, null],
			default: "auto"
		},
		allowNull: {
			type: Boolean,
			default: true
		},
		nullableLabel: {
			type: String,
			default: null
		},
		closeOnSelect: {
			type: Boolean,
			default: true
		},
		loading: {
			type: Boolean,
			default: false
		},
		allowEmpty: {
			type: Boolean,
			default: true
		},
		max: {
			type: Number,
			default: null
		},
		tooltip: {
			type: [String, null],
			default: null
		},
		tooltipPosition: {
			type: [String, null],
			default: "top"
		},
		disabled: {
			type: Boolean,
			default: false
		},
		error: {
			type: [String, Boolean, null],
			default: false
		},
		addSearchValue: {
			type: Boolean,
			default: true
		},
		dataTestid: {
			type: String,
			default: null
		},
		isInline: {
			type: Boolean,
			default: false
		},
		taggable: {
			type: Boolean,
			default: false
		},
		tagPlaceholder: {
			type: [String, null],
			default: ""
		}
	},

	data() {
		return {
			content: this.modelValue
		};
	},

	emits: [
		"update:modelValue",
		"mounted",
		"change",
		"open",
		"close",
		"addTag"
	],

	watch: {
		modelValue: {
			handler: function () {
				if (this.options !== null && this.modelValue !== null) {
					if (this.isOrderedValues) {
						const orderedOptions = [];
						this.modelValue.forEach((value) => {
							const option = this.options.find((content) => {
								return content.value === value;
							});
							if (option) {
								orderedOptions.push(option);
							}
						});

						this.content = [...orderedOptions];
					} else {
						this.content = this.options.filter((content) => {
							return (
								this.modelValue.indexOf(content.value) !== -1
							);
						});
					}
				}
			},
			deep: true
		},
		options: {
			immediate: true,
			handler: function (val) {
				if (this.modelValue !== null) {
					if (this.isOrderedValues) {
						const orderedOptions = [];
						this.modelValue.forEach((value) => {
							const option = val.find((content) => {
								return content.value === value;
							});
							if (option) {
								orderedOptions.push(option);
							}
						});

						this.content = [...orderedOptions];
					} else {
						this.content = val.filter((content) => {
							return (
								this.modelValue.indexOf(content.value) !== -1
							);
						});
					}
				}
			},
			deep: true
		}
	},

	mounted() {
		this.$emit("mounted");
	},

	methods: {
		addTag(newTag) {
			this.$emit("addTag", newTag);
		},
		handleChanged(selectedFields) {
			const final = selectedFields.map((field) => {
				return field.value;
			});
			this.$emit("update:modelValue", final);
			this.$emit("change", final);
		},

		handleOpen() {
			EventBus.$emit("ws-form-select-opened", true);
			this.$emit("open");
		},

		handleClose() {
			EventBus.$emit("ws-form-select-opened", false);
			this.$emit("close");
		},

		openSelect() {
			const vSelect = this.$refs.vSelect;
			if (vSelect) {
				vSelect.activate();
			}
		}
	},

	components: {
		VueMultiselect
	}
};
</script>

<style lang="scss">
.multiselect__tag {
	color: $color-on-grey-300 !important;
	background: $color-grey-300 !important;

	top: 0.25rem;
	cursor: pointer;
}
.multiselect__tag-icon::after {
	color: $color-on-grey-300 !important;
}
.multiselect--active {
	z-index: 10;
}

.multiselect__select {
	min-height: 40px !important;
}
.multiselect__placeholder {
	color: $input-placeholder-color !important;
	padding-top: 7px !important;
}
</style>

<style lang="scss" scoped>
.label-description {
	display: block;
	font-size: $size-7;
	font-weight: $weight-normal;
	color: $grey;
	margin-top: -0.5rem;
	margin-bottom: 0.2rem;
}
.inlineLabel {
	line-height: 40px;
	margin: 0;
	width: 200px !important;
	min-width: 200px;
	max-width: 200px;
}
.inputField {
	width: 100%;
}
.optional {
	font-weight: 400;
	font-size: smaller;
	font-style: italic;
}
</style>
