<template>
	<div class="app" v-if="userAuthenticated">
		<div class="app-content">
			<slot />
		</div>

		<chat-launcher />
	</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import i18n from "@/lang/lang.js";
import AuthService from "@/services/Auth.service.js";
import ChatLauncher from "./components/chat-launcher/zendesk.vue";
import EventBus from "@/eventbus.js";
import { useDayjs } from "@/plugins/dayjs/dayjs.plugin.js";
import { useSessionKeeper } from "@/plugins/keep-session/keep-session.plugin.js";
import { useLogger } from "@/plugins/logger/logger.plugin.js";

const { initKeepSession } = useSessionKeeper();
const { error: $logError } = useLogger();
const { $setLocale } = useDayjs();

const userAuthenticated = ref(false);
const user = ref(null);

onMounted(async () => {
	try {
		// this is a hack to avoid showing anything that would "crash" because user is not logged in
		user.value = await AuthService.getUserInfo();
		const lang = user.value.language;
		i18n.locale.value = lang;
		EventBus.$emit("changedLanguage", lang);
		$setLocale(lang);

		userAuthenticated.value = true;
		// init session tracker
		initKeepSession();
	} catch (error) {
		$logError(
			"Ops! You're not logged in, sorry! You'll be redirected to login page in a few seconds!",
			error
		);
	}
});
</script>

<style lang="scss" scoped>
.app {
	overflow: hidden;
	display: flex;
	height: 100vh;
	background-color: $background;

	.app-content {
		background-color: $background;
		flex: 1 1 0%;
		overflow-x: auto;
		overflow-y: scroll;

		&:focus {
			outline: 2px solid transparent;
			outline-offset: 2px;
		}
	}
}
</style>
