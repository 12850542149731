import helpers from "@/helpers/functions.helper.js";

export function getLatestValueFromPropertyTransformer(data) {
	const dataJson = JSON.parse(data, (key, value, context) => {
		try {
			if (
				["writePayload", "writeScaledPayload", "payload"].includes(
					key
				) &&
				helpers.isBigInt(context.source)
			) {
				return BigInt(context.source);
			}

			return value;
		} catch {
			return value;
		}
	});
	const payload = dataJson.payload;
	try {
		const payloadNumber = Number(payload);
		if (
			!Number.isNaN(payloadNumber) &&
			!Number.isSafeInteger(payloadNumber)
		) {
			return helpers.parseJsonWithBigInt(data);
		} else {
			return dataJson;
		}
	} catch {
		return dataJson;
	}
}
