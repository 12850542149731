/**
 * DEPRECATED
 * Default LoRaWAN properties used pre-fascia to get system properties for LoRa equipment.
 * Properties might be in double in Fascia or Codec response, they (API's) are equipment properties instead of default ones.
 */

export const snr = {
	propertyId: "5e36b032-03d1-41f7-9306-05b156cd0559",
	name: "Signal Noise Ratio (SNR)",
	description: "Sensor signal noise ratio (SNR) value",
	accessType: "REMOTE_READ_ONLY",
	codecPropertyId: "@sys-snr",
	codecDataType: "float",
	unit: "dB"
};

export const rssi = {
	propertyId: "3a0540a4-2db2-427d-b0c9-0cdbab416632",
	name: "Received Signal Strength Indication (RSSI)",
	description: "Sensor received signal strength indication (RSSI) value",
	accessType: "REMOTE_READ_ONLY",
	codecPropertyId: "@sys-rssi",
	codecDataType: "integer",
	unit: "dBm"
};

export const uplink = {
	propertyId: "37120138-a788-4208-af80-7a565e935175",
	name: "Raw encoded payload",
	description: "Combination of raw encoded payload and it's respective port",
	accessType: "REMOTE_READ_ONLY",
	codecPropertyId: "@sys-rawPayload-uplink",
	codecDataType: "string",
	unit: null
};

export const sysdownlink = {
	propertyId: "76f02ae4-6657-46ca-80b6-13c7a26df8f7",
	name: "Encoded downlink message",
	description:
		"Send a combination of raw encoded payload (in Hex) and it's respective port <hex_message>:<port>",
	accessType: "REMOTE_WRITE_ONLY",
	codecPropertyId: "@sys-rawPayload-downlink",
	codecDataType: "string",
	unit: null
};

export const spreadingFactor = {
	propertyId: "79265731-0642-4db4-8476-98624c4958cd",
	name: "Spreading Factor (SF)",
	description: "The value of the spreading factor for the lorawan sensor",
	accessType: "REMOTE_READ_ONLY",
	codecPropertyId: "@sys-sf",
	codecDataType: "integer",
	unit: null,
	scheduleEncoderType: null
};

export const frameCount = {
	propertyId: "3adf65b0-ceda-11ed-afa1-0242ac120002",
	name: "Frame counter",
	description: "Number of uplinks as counted by the equipment",
	accessType: "REMOTE_READ_ONLY",
	codecPropertyId: "@sys-frame-cnt",
	codecDataType: "integer",
	unit: null
};

export const batteryLevel = {
	propertyId: "6f611aee-ca7b-40ab-8a90-8f1621c26213",
	name: "Battery level",
	description: "Percentage between 0 (empty) and 100 (full or powered)",
	accessType: "REMOTE_READ_ONLY",
	codecPropertyId: "@sys-battery-level",
	codecDataType: "integer",
	unit: null
};

export default {
	snr,
	rssi,
	uplink,
	sysdownlink,
	spreadingFactor,
	frameCount,
	batteryLevel
};
