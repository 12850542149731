import axios from "axios";
import helpers from "@/helpers/functions.helper.js";
import MapboxService from "@/services/v1/Mapbox.service.js";
import { $dayjs } from "@/plugins/dayjs/dayjs.plugin.js";
import { $log } from "@/plugins/logger/logger.plugin.js";
import requestAll from "../requestAll.js";
const API = import.meta.env.VITE_API;

// function that will update device tags adding the box address. This way, it won't be needed to request mapbox everytime to convert coordinates to address
const addAddressesToDevices = async (devices) => {
	for (let device of devices) {
		if (device?.tags?.ws_box_geolocation && !device?.tags?.ws_box_address) {
			const boxCoordinates = device.tags.ws_box_geolocation
				.split(";")
				.reverse();
			try {
				const address = await MapboxService.getLocation({
					term: boxCoordinates
				});
				if (address.features.length > 0) {
					await DeviceService.updateDevice(
						{
							tags: {
								...device.tags,
								ws_box_address: address.features[0].place_name
							}
						},
						device.deviceId
					);
				} else {
					$log.error("coordinates dont have address");
				}
			} catch (error) {
				$log.error("failed getLocation from mapbox", error);
			}
		}
	}
};

/**
 * Due to int64 data format for some properties, javascript can't parse them as a Number so it's needed a BigInt handler - json-bigint does that (using json.js and bignumber.js libraries)
 * @param {string json} data
 * @returns {mixed}
 */
function transformResponseWithBigInt(data) {
	if (!data) {
		return [];
	}
	const dataJson = JSON.parse(data);
	for (const value of dataJson) {
		const payload = value.scaledPayload || value.payload;
		try {
			const payloadNumber = Number(payload);
			if (
				!Number.isNaN(payloadNumber) &&
				!Number.isSafeInteger(payloadNumber)
			) {
				return helpers.parseJsonWithBigInt(data);
			}
		} catch {
			return dataJson;
		}
	}
	return dataJson;
}

const DeviceService = {
	deviceId: null,
	listDevices: {},

	setDeviceId(deviceId) {
		this.deviceId = deviceId;
	},

	/**
	 * Get only activated devices.
	 * Pass in {params} organizationId for filter by organization and/or deviceType (BOX, HUB, CLASSICAL_HUB) to filter by type of device
	 *
	 * @param {object} params Pass organizationId to get activated devices from a single organization
	 * @returns {array} Array with activated devices
	 */
	async getDevices(params = {}) {
		return requestAll(`${API}/devices`, params)
			.then((devices) => {
				if (params?.commercialType?.search("HUB") === -1) {
					addAddressesToDevices(devices);
				}
				return devices;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	/**
	 * Get only activated Boxes
	 *
	 * @param {object} params Pass organizationId to get activated boxes from a single organization
	 * @returns {array} Array with activated boxes
	 */
	async getOnlyBoxes(params) {
		return this.getDevices({
			...(params || {}),
			deviceType: "TOWER"
		});
	},

	async activateDevice(params) {
		return axios
			.post(`${API}/devices/activate`, params)
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getDevice(deviceId = null, useCache = true) {
		const _deviceId = deviceId || this.deviceId;

		if (useCache && this.listDevices[_deviceId]) {
			return Promise.resolve(this.listDevices[_deviceId]);
		}

		return axios
			.get(`${API}/devices/${_deviceId}`)
			.then((res) => {
				this.listDevices[_deviceId] = res.data;
				return res.data;
			})
			.catch((err) => {
				throw err.response;
			});
	},

	async getAllProperties(propertyIds, params, deviceId = null) {
		const fourteenDaysAgo = $dayjs().subtract(14, "days").valueOf();
		const now = $dayjs().valueOf();

		return requestAll(
			`${API}/devices/${deviceId || this.deviceId}/properties`,
			{
				property: propertyIds,
				since: fourteenDaysAgo,
				until: now,
				sort: "timestamp,desc",
				...(params || {})
			},
			{
				transformResponse: [
					function (data) {
						return transformResponseWithBigInt(data);
					}
				]
			}
		)
			.then((data) => data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getProperties(
		property = null,
		size = 20,
		page = 1,
		deviceId = null,
		timestampSince = null,
		timestampUntil = null
	) {
		const fourteenDaysAgo = $dayjs().subtract(14, "days").valueOf();
		const now = $dayjs().valueOf();

		const params = helpers.removeNullParamsFromObject({
			property: property,
			size: size,
			page: page,
			since: timestampSince || fourteenDaysAgo,
			until: timestampUntil || now,
			sort: "timestamp,desc"
		});
		return axios
			.get(`${API}/devices/${deviceId || this.deviceId}/properties`, {
				params,
				transformResponse: [
					function (data) {
						return transformResponseWithBigInt(data);
					}
				]
			})
			.then((res) => res.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async setProperty({
		propertyId = null,
		payload = null,
		payloadType = null,
		deviceId = null,
		isScaled = null
	}) {
		if (!propertyId) {
			throw Error("You must provide a property Id or property slug");
		}

		let setOutOfService = null;
		if (
			payload &&
			Object.prototype.hasOwnProperty.call(payload, "setOutOfService")
		) {
			setOutOfService = payload.setOutOfService;
			payload.setOutOfService = null;
		}

		let final = {
			payload: payload,
			nullify: payload === null,
			setOutOfService
		};

		if (payloadType) {
			final.payloadType = payloadType;
		}
		if (isScaled !== null) {
			final.isScaled = isScaled;
		}
		return axios
			.put(
				`${API}/devices/${
					deviceId || this.deviceId
				}/properties/${propertyId}`,
				final
			)
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getBacnetAutoDiscovery(networkId = null, deviceId = null) {
		if (networkId == null) {
			throw Error("You must provide Network ID");
		}

		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/configs/current/networks/${networkId}/discovery`
			)
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async requestAutoDiscovery(networkId = null, deviceId = null) {
		if (networkId == null) {
			throw Error("You must provide Network ID");
		}

		return this.sendCommand("DISCOVER", [networkId], deviceId);
	},

	async sendCommand(command, commandArguments = [], deviceId = null) {
		return axios
			.post(`${API}/devices/${deviceId || this.deviceId}/commands`, {
				command: command,
				arguments: commandArguments
			})
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getCommand(commandId = null, deviceId = null, params = {}) {
		if (commandId == null) {
			throw Error("You must provide a Command ID");
		}

		return axios
			.get(
				`${API}/devices/${
					deviceId || this.deviceId
				}/commands/${commandId}`,
				{ params }
			)
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getListCommands(paramsOrDeviceId = null, _deviceId = null) {
		const deviceId =
			paramsOrDeviceId && typeof paramsOrDeviceId === "string"
				? paramsOrDeviceId
				: _deviceId;
		const params =
			paramsOrDeviceId &&
			!Array.isArray(paramsOrDeviceId) &&
			typeof paramsOrDeviceId === "object"
				? paramsOrDeviceId
				: {};
		return axios
			.get(`${API}/devices/${deviceId || this.deviceId}/commands`, {
				params
			})
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async getEvents(deviceId = null, params = {}) {
		return axios
			.get(`${API}/devices/${deviceId || this.deviceId}/events`, {
				params
			})
			.then((resp) => resp.data)
			.catch((err) => {
				throw err.response;
			});
	},

	async updateDevice(params, deviceId = null) {
		return axios
			.put(`${API}/devices/${deviceId || this.deviceId}`, params)
			.then((resp) => resp.data)
			.then((data) => {
				this.listDevices[deviceId || this.deviceId] = data;
				return data;
			})
			.catch((err) => {
				throw err.response;
			});
	}
};

export default DeviceService;
