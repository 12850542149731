<template>
	<div v-if="loadedConsoleStrings">
		<ws-cache-refresher />
		<component :is="layout">
			<router-view />
		</component>
		<ws-loading-spinner />

		<div id="modals" />
		<div id="root" />
	</div>
</template>

<script>
export default {
	name: "App",

	data() {
		return { loadedConsoleStrings: false };
	},

	async created() {
		const client = this.$clientName;
		await this.$setClientTitleAndFavicon();

		// needed to use the correct product names in i18n files (with modifiers)
		this.loadedConsoleStrings = true;

		// eslint-disable-next-line
		console.log(
			"Prod: " + import.meta.env.PROD,
			"Dev: " + import.meta.env.DEV,
			client
		);

		// when creates the app, check if there's a cookie called wsauthcookie
		// if yes, add it to localStorage because it will be the x-auth-token
		const getCookieFromName = (cookieName) => {
			const cookies = decodeURIComponent(document.cookie)?.split(";");
			for (const cookie of cookies) {
				if (
					cookie?.toString()?.trim()?.indexOf(`${cookieName}=`) === 0
				) {
					return cookie.substring(`${cookieName}=`.length + 1);
				}
			}

			return "";
		};

		const authCookie = getCookieFromName("wsauthtoken");
		if (
			authCookie &&
			authCookie !== "" &&
			!localStorage.getItem(import.meta.env.VITE_AUTH_TOKEN)
		) {
			localStorage.setItem(import.meta.env.VITE_AUTH_TOKEN, authCookie);
		}
	},

	computed: {
		layout() {
			return (
				(this?.$route?.meta?.layout || "app").toLowerCase() + "-layout"
			);
		}
	}
};
</script>
