<template>
	<div
		class="card-box p-4 my-2 mt-0 mb-4"
		:class="{
			isNew: props.status === 'new',
			isEditing: props.status === 'editing',
			isSuccess: props.status === 'success',
			isError: props.status === 'error',
			isWarning: props.status === 'warning',
			disabled: props.disabled,
			'is-clickable': !props.disabled
		}"
		@click="emits('edit')"
	>
		<div
			class="is-flex is-justify-content-space-between is-align-items-center"
		>
			<div
				class="has-text-weight-bold is-size-6 mb-2 is-flex"
				v-if="!!$slots.title"
			>
				<ws-icon
					icon="alert"
					v-if="
						props.status === 'error' || props.status === 'warning'
					"
					class="mr-1"
					:class="{
						'has-text-danger': props.status === 'error',
						'has-text-warning': props.status === 'warning'
					}"
				/>
				<slot name="title" />
				<div v-if="props.status === 'success'" class="success-label">
					{{ $t("success") }}
				</div>
			</div>
			<div v-if="props.status !== 'new' && props.showDeleteBtn">
				<ws-button
					is-subtle
					is-grey
					is-small
					@click="emits('delete')"
					:tooltip="$t('delete')"
					class="delete-btn"
				>
					<template #icon>
						<ws-icon icon="trash" />
					</template>
				</ws-button>
			</div>
		</div>

		<div class="is-size-7">
			<slot name="body" />
		</div>
	</div>
</template>
<script setup>
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();

const emits = defineEmits(["edit", "delete"]);

const props = defineProps({
	showDeleteBtn: {
		type: Boolean,
		default: true
	},
	status: {
		type: String,
		default: "editing",
		validation: function (value) {
			return [
				"done",
				"new",
				"editing",
				"error",
				"warning",
				"success"
			].includes(value);
		}
	},
	disabled: {
		type: Boolean,
		default: false
	}
});
</script>
<style lang="scss" scoped>
.card-box {
	border-radius: 6px;
	border: 1px solid $color-grey-300;
	background: $white;

	.delete-btn {
		display: none;
	}
	&:hover:not(.disabled) {
		box-shadow: $box-shadow-radio-card-hover;
		.delete-btn {
			display: flex;
			z-index: 1;
		}
	}
}
.isNew {
	height: 6.5rem;
}
.isNew,
.isEditing {
	border-radius: 6px;
	border: 2px dashed $color-primary-500;
	background: $white;
}
.isSuccess {
	border: 1px solid $color-success-500;
	.success-label {
		z-index: 2;
		display: block;
		position: absolute;
		height: 2.5rem;
		padding: 0.5rem 1rem;
		top: 1rem;
		right: 1rem;
		background: $color-success-500;
		color: $color-on-success-500;
		border-radius: 0 6px;
	}
}
.isError {
	border: 1px solid $color-danger-500;
}
.isWarning {
	border: 1px solid $color-warning-500;
}

li {
	list-style-type: disc;
	list-style-position: inside;
	font-size: 0.75rem;
	::marker {
		padding: 10px;
	}
}
</style>
