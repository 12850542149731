<template>
	<div class="field" :data-testid="props.fieldDataTestid">
		<ws-form-label :id="id" :tooltip="props.tooltip" :error="props.error">
			{{ props.label }}
		</ws-form-label>
		<p class="help" v-if="!!props.labelHelp">{{ props.labelHelp }}</p>
		<div
			class="custom-selector"
			:data-testid="props.dataTestid"
			:aria-labelledby="id"
		>
			<div
				v-for="option in props.options"
				:key="option.value"
				:tabindex="option.disabled ? -1 : 0"
				@click="selectOption(option)"
				@keypress.space.prevent="selectOption(option)"
				@keypress.enter.prevent="selectOption(option)"
				class="selector-option"
				:class="{
					selected: isSelectedOption(option.value),
					disabled: props.disabled || option.disabled
				}"
				:id="option.id"
				:data-testid="`${props.dataTestid}-${option.value.toLowerCase()}`"
			>
				<div>
					<div class="is-flex">
						<ws-icon v-if="option.icon" :icon="option.icon" />
						<div id="option-label" v-html="option.label" />
					</div>
					<div v-if="option.desc">{{ option.desc }}</div>
				</div>

				<span id="icon" class="check-icon">
					<ws-icon
						icon="check"
						v-if="isSelectedOption(option.value)"
					/>
				</span>
			</div>
		</div>
		<p
			class="help is-danger"
			v-if="!!props.error"
			:data-testid="`${props.fieldDataTestid}-error`"
		>
			{{ props.error }}
		</p>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { generateRandomId } from "@/helpers/functions.helper.js";
const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
	id: {
		type: String,
		default: () => {
			return "label-custom-selector-" + generateRandomId();
		}
	},
	modelValue: {
		type: String,
		default: null
	},

	options: {
		type: Array,
		required: true,
		validation: (value) => {
			if (!Array.isArray(value)) {
				return false;
			}
			for (const item of value) {
				const keys = Object.keys(item);
				if (!keys.includes("label") || !keys.includes("value")) {
					return false;
				}
			}

			return true;
		}
	},

	label: {
		type: String,
		default: null
	},
	labelHelp: {
		type: String,
		default: null
	},
	tooltip: {
		type: String,
		default: null
	},
	error: {
		type: String,
		default: null
	},
	disabled: {
		type: Boolean,
		default: false
	},
	fieldDataTestid: {
		type: String,
		default: "custom-selector-field"
	},
	dataTestid: {
		type: String,
		default: "custom-selector"
	}
});

const selectedOption = computed(() => props.modelValue || null);

function isSelectedOption(option) {
	// in case there's no selectedOption (null), return false
	return selectedOption.value && selectedOption.value === option;
}

function selectOption(option) {
	if (props.disabled || option.disabled) {
		return;
	}
	emits("update:modelValue", option.value);
}
</script>

<style scoped lang="scss">
.custom-selector {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 16px;
	max-width: 700px;

	.selector-option {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		padding: 16px;
		border-radius: 6px;
		border: 1px solid $color-grey-300;
		cursor: pointer;

		&.selected {
			border-color: $color-grey-700;
			&.disabled {
				background-color: $color-grey-200;
			}
		}

		&:hover:not(.disabled) {
			box-shadow: $box-shadow-radio-card-hover;
		}

		&.disabled {
			border-color: $color-grey-300;
			cursor: not-allowed;
		}

		@include tablet {
			min-width: 315px;
		}

		div#option-label {
			@include tablet {
				width: 100%;
			}
		}
		.check-icon {
			width: 1.5rem;
		}
	}
}
</style>
