<template>
	<main
		class="panel-main"
		:class="{ 'overflow-visible': overflowVisible }"
		ref="scrollContainer"
	>
		<slot />
	</main>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { EventBus } from "@/eventbus";

const overflowVisible = ref(false);

onMounted(() => {
	EventBus.$on("ws-form-select-opened", changeOverflowVisible);
});

onUnmounted(() => {
	EventBus.$off("ws-form-select-opened", changeOverflowVisible);
});
/**
 * This will turn overflow to visible to show the entire vue-multiselect component
 */
const changeOverflowVisible = (status) => {
	overflowVisible.value = status;
};
</script>

<style lang="scss" scoped>
main.panel-main {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: flex-start;
	align-content: flex-start;
	padding: 1.5rem 1rem;
	overflow-x: hidden;
	position: relative;
	width: 100%;

	&.overflow-visible {
		overflow-y: visible !important;
	}
}
</style>
